<template>
  <div id="user-container" v-if="accessibleRoutes.user">
    <div id="user-btn-group" no-gutters>
      <div class="col-12 p-responsive user-tabs-responsive">
        <div class="col-12">
          <v-btn-toggle v-model="toggle">
            <router-link to="/users/list" :style="`${toggle == 0 ? 'background-color: #000000 !important;' : 'background-color: #b5b5b5 !important; opacity: 0.4;'}`" style="opacity: 0.4; pointer-events: none;">
              <v-btn :ripple="false" :disabled="toggle == 0" :class="`${toggle == 0 ? 'active-menu' : 'inactive-menu'}`"> Create User </v-btn>
            </router-link>
            <router-link to="/users/reset-password" class="tab-3-menu" :style="`${toggle == 1 ? 'background-color: #000000 !important;' : 'background-color: #b5b5b5 !important; opacity: 0.4;'}`">
              <v-btn :ripple="false" :disabled="toggle == 1" :class="`${toggle == 1 ? 'active-menu' : 'inactive-menu'}`"> Reset Password </v-btn>
            </router-link>
          </v-btn-toggle>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0 pb-0 user-view-responsive">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
export default {
  data() {
    return {
      toggle: 0,
    };
  },
  created() {
    this.toggle = 0;
    if (this.$router.currentRoute.name == "User Reset Password") {
      this.toggle = 1;
    } else if (this.$router.currentRoute.name == "Settings") {
      this.toggle = 2;
    } else if (this.$router.currentRoute.name == "Logs") {
      this.toggle = this.accessibleRoutes.setting ? 3 : 2;
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    }
  },
};
</script>
